.rumah{
    width: 100%;
    margin-top: 50px;
    @media (max-width: 768px){
        
    }
    .judul-residential{
      width: 100%;
      text-align: center;
      font-size: 50px;
      font-family: "poppins" sans-serif;
      font-weight: 600;
      //text-shadow: 2px 2px 4px rgb(170, 170, 170);
      color: #bd933e;
      @media (max-width: 768px) {
          font-size: 30px;
      }
    }
    .container-header1{
        justify-content: center;
        margin-top: 4%;
        font-size: 50px;
        font-family: 'Poppins';
        font-weight: 500;
        text-align: center;
        color:#605438 ;
        @media (max-width: 768px){
            margin-top: -15%;
            font-size: 36px;
        }
    }
    .Judul{
        justify-content: center;
        margin-top: 1%;
        margin-bottom: 10px;
        font-size: 40px;
        font-family: 'Poppins' sans-serif;
        font-weight: 600;
        text-align: center;
        color: #1f565c;
        @media (max-width: 768px){
            //margin-top: -15%;
            font-size: 24px;
            margin-bottom: 20%;
        }
    }
    .rumah-card{
        @media (max-width: 768px){
            margin-top: -15%;}
    }
}
