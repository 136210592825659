.ruko{
width: 100%;
margin-top: -50px;
    @media (max-width: 768px){
        margin-top: -75px;
    }
    
    .container-header1{
        justify-content: center;
        margin-top: -4%;
        padding-bottom: 10%;
        font-size: 50px;
        font-family: 'Poppins';
        font-weight: 500;
        text-align: center;
        color: #1f565c;
        @media (max-width: 768px){
            font-size: 36px;
        }
    }
    .Judul{
        justify-content: center;
        margin-top: 1%;
        margin-bottom: 10px;
        font-size: 40px;
        font-family: 'Poppins' sans-serif;
        font-weight: 600;
        text-align: center;
        color: #172e69;
        @media (max-width: 768px){
            //margin-top: -15%;
            margin-bottom: 30px;
            font-size: 24px;
        }
    }
    .rumah-card{
        @media (max-width: 768px){
            margin-top: -15%;}
    }
    .judul-komersil{
      width: 100%;
      margin-top: 100px;
      text-align: center;
      font-size: 50px;
      font-family: "poppins" sans-serif;
      font-weight: 600;
      //text-shadow: 2px 2px 4px rgb(170, 170, 170);
      color: #C88C39;
      @media (max-width: 768px){
        //margin-top: -2%;
        font-size: 25px;
      }
    }
    .cluster{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: -30px;
        margin-bottom: 20px;
        @media (max-width: 768px) {
        }
        .devider-cluster {
          margin-top: 50px;
          background: rgb(0,88,28);
background: linear-gradient(103deg, rgba(0,88,28,1) 30%, rgba(73,110,0,1) 44%, rgb(244, 244, 244) 65%);
          width: 100%;
          padding-top: 20px;
          padding-bottom: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          @media (max-width: 768px) {
            flex-direction: column;
            padding-bottom: 50px;
            margin-bottom: 20px;
            background: linear-gradient(103deg, rgba(0,88,28,1) 30%, rgba(73,110,0,1)70%,) ;
          }
          .judul-cluster {
            width: 100%;
            text-align: center;
            color: White;
            font-weight: 400;
            font-size: 30px;
            line-height: 1em;
            padding-bottom: 10px;
            font-family: "Poppins", sans-serif;
            @media (max-width: 768px) {
              font-size: 25px;
              margin-bottom: 35px;
            }
          }
          .carousel-logocluster {
            width: 50%;
            display: flex;
            justify-content: center;
            @media (max-width: 768px) {
              width: 150%;
              padding: 0px;
              margin: -30px 0px;
            }
            .slick-slider {
              width: 70%;
              margin: auto;
              @media (max-width: 768px){
                width: 100%;
                padding: 0px;
                margin: 0px;
              }
              .slick-next::before,
              .slick-prev::before {
                font-family: "slick";
                font-size: 30px;
              }
              .slick-list {
                border-radius: 10px;
              }
              .slick-track {
                display: flex;
                justify-content: center;
                align-items: center;
                align-self: center;
              }
      
              .slick-dots {
                position: absolute; /* Make dots absolute for positioning within slides / / Adjust vertical positioning */
                left: 50%;
                bottom: -15px; /* Center dots horizontally */
                transform: translateX(-50%); /* Center dots precisely */
                z-index: 1; /* Ensure dots are above image content */
              }
              .slick-next {
                right: 25px;
              }
              .slick-prev {
                z-index: 1;
                left: 25px;
              }
              .slick-dots {
                li {
                  margin: 0;
                }
              }
            }
          }
          .kartugambar12 {
            @media (max-width: 768px){
              width: 300%;
              padding: 0px;
              margin: 0px;
            }
          } 
        }
      }
}
