.container-aboutparkspring {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1%;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
  .logo-aboutparkspring {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 25%;
    @media (max-width: 768px) {
      width: 100%;
    }
    .gambar-logoparkspring {
      width: 30%;
      @media (max-width: 768px) {
        width: 60%;
        margin-left: -50%;
        margin-bottom: -50px;
      }
    }
  }
  .container-deskripsiparkspring {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    line-height: 30px;
    margin-bottom: 20px;
    h1 {
      color: #ad7e36;
      font-family: sans-serif;
      //margin-top: -20px;
    
    @media (max-width: 768px) {
      width: 100%;
      margin-top: 70px;
      display: flex;
      font-size: 25px;
      justify-content: center;
    }
  }
    .deskripsi-aboutparkspring {
      width: 80%;
      font-size: 20px;
      margin-left: 10%;
      text-align: center;

      color: #7a7a7a;
      @media (max-width: 768px) {
        width: 80%;
        font-size: 15px;
        text-align: center;
      }
    }
  }
}
